.past-incidents-section {
  margin: 20px auto;
  padding: 20px;
  width: 850px;
  text-align: left;
  box-sizing: border-box;
  color: rgb(230, 230, 230);
  max-height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.past-incidents-section h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.incidents-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding-right: 15px;
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #1a1a1a;
}

.incidents-list::-webkit-scrollbar {
  width: 8px;
}

.incidents-list::-webkit-scrollbar-track {
  background: #1a1a1a;
  border-radius: 4px;
}

.incidents-list::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 4px;
}

.incidents-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.incident-item {
  padding: 15px;
  border-radius: 8px;
}

.incident-date {
  font-size: 14px;
  color: #878787;
  margin-bottom: 5px;
}

.incident-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.incident-details {
  font-size: 16px;
  color: rgb(230, 230, 230);
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .past-incidents-section {
    padding: 15px;
    width: 95%;
    max-height: 500px;
  }

  .past-incidents-section h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .incident-item {
    padding: 10px;
  }

  .incident-title {
    font-size: 16px;
  }

  .incident-details {
    font-size: 14px;
  }
}
