.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  background-color: #0b0b0b;
}

.main-content {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.status-label.operational {
  background-color: rgb(14, 149, 111);
}

.status-label.degraded {
  background-color: rgb(227, 212, 113);
  color: #0b0b0b;
}

.status-label.down {
  background-color: rgb(239, 67, 67);
  color: #0b0b0b;
}
