.navbar {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  padding: 15px;
  width: 90%;
  max-width: 850px;
  border-bottom: 2px solid #acacac;
  background-color: #0b0b0b;
  color: rgb(230, 230, 230);
  margin: 0 auto;
  position: relative; /* Allows the logo to be positioned absolutely */
}

.navbar-brand {
  position: absolute;
  left: 0; /* Align the logo to the left */
  display: flex;
  align-items: center;
}

.logo {
  height: 55px;
  margin-left: 15px; /* Adjust for desired spacing */
}

.navbar h1 {
  font-size: 28px;
  margin: 0;
  text-align: center;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .navbar-brand {
    position: static; /* Resets position for mobile */
    margin-bottom: 10px;
  }

  .logo {
    margin: 0; /* Remove extra margins for mobile */
    margin-bottom: 5px;
  }

  .navbar h1 {
    font-size: 24px; /* Adjust font size for mobile */
  }
}
