.footer {
  padding: 20px;
  background-color: #0b0b0b;
  color: #878787;
  text-align: center;
  border-top: 2px solid #acacac;
  position: relative;
  bottom: 0;
  width: 850px;
  margin: 0 auto; /* Centers the content within the footer */
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .footer {
    padding: 15px;
    width: 90%; /* Full width on mobile */
    font-size: 0.8rem;
  }
}
