.status-section {
  margin: 0 auto 30px;
  padding: 20px;
  background-color: #0b0b0b;
  width: 100%;
  max-width: 850px;
  text-align: center;
  box-sizing: border-box;
}

.system-status {
  margin: 25px -20px 55px;
}

.status-label {
  display: block;
  font-size: 24px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 8px;
  color: white;
  text-align: center;
  background-color: #878787;
}

.status-label.All-Systems-Operational {
  background-color: rgb(14, 149, 111);
}

.status-label.Potential-Minor-Service-Disruption {
  background-color: rgba(227, 212, 113, 0.881);
}

.status-label.Performance-Issues-Detected {
  background-color: rgba(227, 212, 113, 0.881);
}

.status-label.Partial-System-Outage {
  background-color: rgb(238, 103, 103);
}

.status-label.All-Systems-Down {
  background-color: rgb(238, 103, 103);
}

.uptime-message {
  font-size: 14px;
  margin-bottom: 5px;
  color: #878787;
  text-align: left;
}

.status-item {
  padding: 15px;
  border-radius: 8px;
  background-color: #0b0b0b;
  margin-bottom: 15px;
  display: block;
  text-align: left;
  box-sizing: border-box;
  color: rgb(230, 230, 230);
}

.status-days {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 10px;
}

.status-day {
  width: 6px;
  height: 35px;
  border: 1px solid #ffffff16;
  border-radius: 1px;
}

.Tooltip {
  background-color: #343434;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.status-day.operational {
  background-color: rgb(14, 149, 111);
}

.status-day.degraded {
  background-color: rgba(227, 212, 113, 0.881);
}

.status-day.down {
  background-color: rgb(238, 103, 103);
}

.service-status-list {
  margin-bottom: 40px;
}

.service-status-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.service-status-item span {
  margin-left: 15px;
  font-size: 20px;
}

.status-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}

.status-circle.operational {
  background-color: rgb(14, 149, 111);
}

.status-circle.degraded {
  background-color: rgba(227, 212, 113, 0.881);
}

.status-circle.down {
  background-color: rgb(238, 103, 103);
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .status-section {
    padding: 10px;
  }

  .system-status {
    margin: 15px -10px 30px;
  }

  .status-label {
    font-size: 20px;
    padding: 10px 15px;
  }

  .status-item {
    padding: 10px;
    margin-bottom: 10px;
  }

  .service-status-item {
    flex-wrap: wrap;
  }

  .service-status-item span {
    margin-left: 10px;
    font-size: 18px;
  }

  .status-days {
    justify-content: center;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .status-label {
    font-size: 18px;
    padding: 8px 12px;
  }

  .service-status-item span {
    font-size: 16px;
  }

  .status-day {
    width: 4px;
    height: 25px;
  }
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.service-group {
  margin-bottom: 10px;
}

.service-status-item.clickable {
  cursor: pointer;
}

.service-status-item .expand-icon {
  margin-left: auto;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.service-status-item .expand-icon.expanded {
  transform: rotate(180deg);
}

.group-services {
  margin-top: 5px;
  padding-left: 20px;
}

.service-status-item.nested {
  padding: 5px 0;
  font-size: 0.9em;
}

.status-banner {
  padding: 15px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: white;
  transition: all 0.3s ease;
}
